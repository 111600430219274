var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nr" }, [
      _c("div", { staticClass: "bananer_box" }, [
        _c("div", { staticClass: "bananer_nav" }, [
          _c("div", { staticClass: "bananer_flex" }, [
            _c("div", { staticClass: "bananer_title" }, [
              _c("span", { staticClass: "underline" }, [_vm._v("政企")]),
              _vm._v(" 行业解决方案 "),
            ]),
            _c("div", { staticClass: "content_nr" }, [
              _vm._v(
                " 面向政企行业量身定制、等保合规的政企专属的中间件管理解决方案，符合国家标准《信息安全技术 云计算服务安全能力》，始终符合国家监管规范与安全要求 "
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "title_level1 titles" }, [_vm._v("方案介绍")]),
        _c("div", { staticClass: "introduce_box" }, [
          _c("div", { staticClass: "introduct_item" }, [
            _c("div", { staticClass: "introduct_title" }, [
              _c("img", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  src: require("../../../assets/solution/government_icon1.png"),
                },
              }),
              _vm._v(" 全面融合 "),
            ]),
            _c("div", { staticClass: "content_nr introduct_nr" }, [
              _vm._v(
                " 基于对政府行业需求的深度理解，将客户需求与智迩的产品技术、生态创新能力、建设运营体系等政府信息化转型要素有机融合，打造“全面融合”新理念新模式 "
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "introduct_item",
              staticStyle: { margin: "0 50px" },
            },
            [
              _c("div", { staticClass: "introduct_title" }, [
                _c("img", {
                  staticStyle: { "margin-right": "10px" },
                  attrs: {
                    src: require("../../../assets/solution/government_icon2.png"),
                  },
                }),
                _vm._v(" 完全国产化 "),
              ]),
              _c("div", { staticClass: "content_nr introduct_nr" }, [
                _vm._v(
                  " 完全自主研发，形成了自主知识产权；现有国产替代已经从IT服务、应用软件深入到以服务器操作系统、中间件为代表的基础软件领域，软件替代产品逐步转向核心部件 "
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "introduct_item" }, [
            _c("div", { staticClass: "introduct_title" }, [
              _c("img", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  src: require("../../../assets/solution/government_icon3.png"),
                },
              }),
              _vm._v(" 可靠的技术支撑 "),
            ]),
            _c("div", { staticClass: "content_nr introduct_nr" }, [
              _vm._v(
                " 从售前到售后，提供一站式服务。根据具体的业务场景和架构体系，兼容现有的技术体系，定制出专业可靠的解决方案，落实实施方案；定期回访交流 "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "title_level1 titles" }, [_vm._v("方案优势")]),
        _c(
          "div",
          {
            staticClass: "advantage_flex",
            staticStyle: { "margin-bottom": "40px" },
          },
          [
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("../../../assets/solution/government_img1.png"),
                },
              }),
            ]),
            _c("div", { staticClass: "advantage_right" }, [
              _c("div", { staticClass: "advantage_title" }, [
                _vm._v("安全合规"),
              ]),
              _c("div", { staticClass: "content_nr" }, [
                _vm._v(
                  "能够在安全合规、稳定极速和省力省心三个维度赋能政企客户；等保合规，稳如泰山"
                ),
              ]),
            ]),
          ]
        ),
        _c("div", { staticClass: "advantage_flex" }, [
          _c("div", { staticClass: "advantage_right" }, [
            _c("div", { staticClass: "advantage_title" }, [_vm._v("高效协同")]),
            _c("div", { staticClass: "content_nr" }, [
              _vm._v(
                " 通过政务服务、政务协同、政府监管、城市治理、科学决策和产业发展等领域体系化的产品能力矩阵，全方位支撑政府的数字化转型和产业升级 "
              ),
            ]),
          ]),
          _c("div", [
            _c("img", {
              attrs: {
                src: require("../../../assets/solution/government_img2.png"),
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "title_level1 titles" }, [
          _vm._v("丰富的产品矩阵"),
        ]),
        _c("div", { staticClass: "product_flex" }, [
          _c("div", { staticClass: "product_item_left" }, [
            _c("div", { staticClass: "product_box" }, [
              _c("div", { staticClass: "product_box_left" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/solution/government_img_icon1.png"),
                    width: "88px",
                    height: "88px",
                  },
                }),
              ]),
              _c("div", { staticClass: "product_box_right" }, [
                _c("div", { staticClass: "product_title" }, [
                  _vm._v("可视化平台"),
                ]),
                _c("div", { staticClass: "content_nr" }, [
                  _vm._v("采用全链路数据治理办法，打造一站式可配置大屏"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "product_item_right" }, [
            _c("div", { staticClass: "product_box" }, [
              _c("div", { staticClass: "product_box_left" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/solution/government_img_icon2.png"),
                    width: "88px",
                    height: "88px",
                  },
                }),
              ]),
              _c("div", { staticClass: "product_box_right" }, [
                _c("div", { staticClass: "product_title" }, [
                  _vm._v("数据开放"),
                ]),
                _c("div", { staticClass: "content_nr" }, [
                  _vm._v(
                    "平台提供丰富的 API ，提供基于数据计算技术的数据服务、标准、集成、模型、开发、安全等"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "product_flex" }, [
          _c("div", { staticClass: "product_item_left" }, [
            _c("div", { staticClass: "product_box" }, [
              _c("div", { staticClass: "product_box_left" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/solution/government_img_icon3.png"),
                    width: "88px",
                    height: "88px",
                  },
                }),
              ]),
              _c("div", { staticClass: "product_box_right" }, [
                _c("div", { staticClass: "product_title" }, [_vm._v("模块化")]),
                _c("div", { staticClass: "content_nr" }, [
                  _vm._v("模块灵活组合，满足不同场景、不同阶段的客户诉求"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "product_item_right" }, [
            _c("div", { staticClass: "product_box" }, [
              _c("div", { staticClass: "product_box_left" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/solution/government_img_icon4.png"),
                    width: "88px",
                    height: "88px",
                  },
                }),
              ]),
              _c("div", { staticClass: "product_box_right" }, [
                _c("div", { staticClass: "product_title" }, [_vm._v("全流程")]),
                _c("div", { staticClass: "content_nr" }, [
                  _vm._v("覆盖“汇聚-治理-分析-决策”的数据价值跃迁全流程"),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "product_flex" }, [
          _c("div", { staticClass: "product_item_left" }, [
            _c("div", { staticClass: "product_box" }, [
              _c("div", { staticClass: "product_box_left" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/solution/government_img_icon5.png"),
                    width: "88px",
                    height: "88px",
                  },
                }),
              ]),
              _c("div", { staticClass: "product_box_right" }, [
                _c("div", { staticClass: "product_title" }, [
                  _vm._v("简单便捷"),
                ]),
                _c("div", { staticClass: "content_nr" }, [
                  _vm._v("简化数据开发过程，降低数据治理成本"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "product_item_right" }, [
            _c("div", { staticClass: "product_box" }, [
              _c("div", { staticClass: "product_box_left" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/solution/government_img_icon6.png"),
                    width: "88px",
                    height: "88px",
                  },
                }),
              ]),
              _c("div", { staticClass: "product_box_right" }, [
                _c("div", { staticClass: "product_title" }, [_vm._v("全面性")]),
                _c("div", { staticClass: "content_nr" }, [
                  _vm._v("完善的数据资源平台产品体系和治理经验"),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }