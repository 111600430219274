<template>
    <div class="nr">
        <div class="bananer_box">
            <div class="bananer_nav">
                <div class="bananer_flex">
                    <div class="bananer_title">
                        <span class="underline">政企</span>
                        行业解决方案
                    </div>
                    <div class="content_nr">
                        面向政企行业量身定制、等保合规的政企专属的中间件管理解决方案，符合国家标准《信息安全技术
                        云计算服务安全能力》，始终符合国家监管规范与安全要求
                    </div>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="title_level1 titles">方案介绍</div>
            <div class="introduce_box">
                <div class="introduct_item">
                    <div class="introduct_title">
                        <img src="../../../assets/solution/government_icon1.png" style="margin-right: 10px" />
                        全面融合
                    </div>
                    <div class="content_nr introduct_nr">
                        基于对政府行业需求的深度理解，将客户需求与智迩的产品技术、生态创新能力、建设运营体系等政府信息化转型要素有机融合，打造“全面融合”新理念新模式
                    </div>
                </div>
                <div class="introduct_item" style="margin: 0 50px">
                    <div class="introduct_title">
                        <img src="../../../assets/solution/government_icon2.png" style="margin-right: 10px" />
                        完全国产化
                    </div>
                    <div class="content_nr introduct_nr">
                        完全自主研发，形成了自主知识产权；现有国产替代已经从IT服务、应用软件深入到以服务器操作系统、中间件为代表的基础软件领域，软件替代产品逐步转向核心部件
                    </div>
                </div>
                <div class="introduct_item">
                    <div class="introduct_title">
                        <img src="../../../assets/solution/government_icon3.png" style="margin-right: 10px" />
                        可靠的技术支撑
                    </div>
                    <div class="content_nr introduct_nr">
                        从售前到售后，提供一站式服务。根据具体的业务场景和架构体系，兼容现有的技术体系，定制出专业可靠的解决方案，落实实施方案；定期回访交流
                    </div>
                </div>
            </div>
            <div class="title_level1 titles">方案优势</div>
            <div class="advantage_flex" style="margin-bottom: 40px">
                <div><img src="../../../assets/solution/government_img1.png" /></div>
                <div class="advantage_right">
                    <div class="advantage_title">安全合规</div>
                    <div class="content_nr">能够在安全合规、稳定极速和省力省心三个维度赋能政企客户；等保合规，稳如泰山</div>
                </div>
            </div>

            <div class="advantage_flex">
                <div class="advantage_right">
                    <div class="advantage_title">高效协同</div>
                    <div class="content_nr">
                        通过政务服务、政务协同、政府监管、城市治理、科学决策和产业发展等领域体系化的产品能力矩阵，全方位支撑政府的数字化转型和产业升级
                    </div>
                </div>
                <div><img src="../../../assets/solution/government_img2.png" /></div>
            </div>

            <div class="title_level1 titles">丰富的产品矩阵</div>
            <div class="product_flex">
                <div class="product_item_left">
                    <div class="product_box">
                        <div class="product_box_left"><img src="../../../assets/solution/government_img_icon1.png" width="88px" height="88px" /></div>
                        <div class="product_box_right">
                            <div class="product_title">可视化平台</div>
                            <div class="content_nr">采用全链路数据治理办法，打造一站式可配置大屏</div>
                        </div>
                    </div>
                </div>
                <div class="product_item_right">
                    <div class="product_box">
                        <div class="product_box_left"><img src="../../../assets/solution/government_img_icon2.png" width="88px" height="88px" /></div>
                        <div class="product_box_right">
                            <div class="product_title">数据开放</div>
                            <div class="content_nr">平台提供丰富的 API ，提供基于数据计算技术的数据服务、标准、集成、模型、开发、安全等</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product_flex">
                <div class="product_item_left">
                    <div class="product_box">
                        <div class="product_box_left"><img src="../../../assets/solution/government_img_icon3.png" width="88px" height="88px" /></div>
                        <div class="product_box_right">
                            <div class="product_title">模块化</div>
                            <div class="content_nr">模块灵活组合，满足不同场景、不同阶段的客户诉求</div>
                        </div>
                    </div>
                </div>
                <div class="product_item_right">
                    <div class="product_box">
                        <div class="product_box_left"><img src="../../../assets/solution/government_img_icon4.png" width="88px" height="88px" /></div>
                        <div class="product_box_right">
                            <div class="product_title">全流程</div>
                            <div class="content_nr">覆盖“汇聚-治理-分析-决策”的数据价值跃迁全流程</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="product_flex">
                <div class="product_item_left">
                    <div class="product_box">
                        <div class="product_box_left"><img src="../../../assets/solution/government_img_icon5.png" width="88px" height="88px" /></div>
                        <div class="product_box_right">
                            <div class="product_title">简单便捷</div>
                            <div class="content_nr">简化数据开发过程，降低数据治理成本</div>
                        </div>
                    </div>
                </div>
                <div class="product_item_right">
                    <div class="product_box">
                        <div class="product_box_left"><img src="../../../assets/solution/government_img_icon6.png" width="88px" height="88px" /></div>
                        <div class="product_box_right">
                            <div class="product_title">全面性</div>
                            <div class="content_nr">完善的数据资源平台产品体系和治理经验</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '../../../assets/css/common.css'
export default {
    name: 'Government',
    data() {
        return {}
    },
    methods: {},
    mounted() {}
}
</script>
<style scoped>
.introduct_nr {
    text-align: justify;
}
.introduct_nrs {
    text-align: justify;
    margin-top: 20px;
}
.introduct_title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}
.introduct_title span {
    border-bottom: 6px solid #409eff;
    padding-bottom: 6px;
}
.introduct_item {
    flex: 1;
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    padding: 30px 30px 60px 30px;
}
.introduce_box {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    align-content: center;
}
/*丰富的产品*/
.product_box_right {
    margin-left: 20px;
}
.product_title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 6px;
}
.product_box {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: space-between;
    padding: 20px;
}
.product_item_left {
    margin-right: 20px;
    /* margin-left: 6%; */
}
.product_item_right {
    margin-left: 20px;
}
.product_item_left,
.product_item_right {
    flex: 1;
    margin-bottom: 30px;
}
.product_flex {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
}
/*相关产品*/
.advantage_title {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 20px;
}
.advantage_flex {
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    padding: 30px 5%;
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
/*方案架构*/
.box {
    width: 80%;
    margin: 0 auto;
}
/*bananer模块*/
.titles {
    text-align: center;
    color: #191c3d;
    font-size: 32px;
    font-weight: bold;
    padding: 50px;
}
.bananer_title .underline {
    border-bottom: 6px solid #409eff;
    padding-bottom: 6px;
}
.bananer_title {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
}
.bananer_flex {
    width: 500px;
    height: 22rem;
    padding: 40px;
    background: #fff;
}
.bananer_nav {
    padding-top: 5rem;
    padding-left: 12%;
}
.bananer_box {
    width: 100%;
    height: 32rem;
    background-image: url(../../../assets/solution/government_bananer.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
</style>
