var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nr" }, [
      _c("div", { staticClass: "bananer_box" }, [
        _c("div", { staticClass: "bananer_nav" }, [
          _c("div", { staticClass: "bananer_flex" }, [
            _c("div", { staticClass: "bananer_title" }, [
              _c("span", { staticClass: "underline" }, [_vm._v("能源")]),
              _vm._v(" 行业解决方案 "),
            ]),
            _c("div", { staticClass: "content_nr" }, [
              _vm._v(
                " 面向石化、电力、新能源等行业整体的中间件管理解决方案，依托自身强大的平台、连接能力，支持一体化服务，快速构建生态化应用 "
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "title_level1 titles" }, [_vm._v("方案介绍")]),
        _c("div", { staticClass: "introduce_box" }, [
          _c("div", { staticClass: "introduct_item" }, [
            _c("div", { staticClass: "introduct_title" }, [
              _c("img", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  src: require("../../../assets/solution/energy_icon1.png"),
                },
              }),
              _vm._v(" 快速支撑建立应用 "),
            ]),
            _c("div", { staticClass: "content_nr introduct_nr" }, [
              _vm._v(
                " 传统能源行业运维是靠人力维护的模式，在信息化建立及业务转型下，系统实现分钟级的中间件交付能力 "
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "introduct_item",
              staticStyle: { margin: "0 50px" },
            },
            [
              _c("div", { staticClass: "introduct_title" }, [
                _c("img", {
                  staticStyle: { "margin-right": "10px" },
                  attrs: {
                    src: require("../../../assets/solution/energy_icon2.png"),
                  },
                }),
                _vm._v(" 支撑海量设备接入 "),
              ]),
              _c("div", { staticClass: "content_nr introduct_nr" }, [
                _vm._v(
                  " 利用自动扫描系统，全面感知海量异构的用能设备数据，降低数据接入成本并进行高压缩存储，并且平台默认支持数据备份，保证数据安全可靠 "
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "introduct_item" }, [
            _c("div", { staticClass: "introduct_title" }, [
              _c("img", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  src: require("../../../assets/solution/energy_icon3.png"),
                },
              }),
              _vm._v(" 实践验证稳定可靠 "),
            ]),
            _c("div", { staticClass: "content_nr introduct_nr" }, [
              _vm._v(
                " 以最高标准践行用户隐私安全相关工作。拥有先进的基础安全架构及完善的数据安全保护体系,能够为⽤户提供从物理到应⽤层⾯的全⽅位安全护航 "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "title_level1 titles" }, [_vm._v("方案优势")]),
        _c(
          "div",
          {
            staticClass: "advantage_flex",
            staticStyle: { "margin-bottom": "40px" },
          },
          [
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("../../../assets/solution/energy_img1.png"),
                },
              }),
            ]),
            _c("div", { staticClass: "advantage_right" }, [
              _c("div", { staticClass: "advantage_title" }, [
                _vm._v("快速接入"),
              ]),
              _c("div", { staticClass: "content_nr" }, [
                _vm._v(
                  " 使用CheerOcean MaaS的标准化快速接入不同类型中间件，使管理员更加便捷，高效的维护和管理，打破传统运维模式 "
                ),
              ]),
            ]),
          ]
        ),
        _c("div", { staticClass: "advantage_flex" }, [
          _c("div", { staticClass: "advantage_right" }, [
            _c("div", { staticClass: "advantage_title" }, [_vm._v("全景洞察")]),
            _c("div", { staticClass: "content_nr" }, [
              _vm._v(
                "数据可视化，拥有可配置大屏，进行专业级大屏展示，实时掌握综合能源平台的动态信息，使数据能直观感知"
              ),
            ]),
          ]),
          _c("div", [
            _c("img", {
              attrs: {
                src: require("../../../assets/solution/energy_img2.png"),
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "title_level1 titles" }, [_vm._v("方案特性")]),
        _c("div", { staticClass: "introduce_box" }, [
          _c("div", { staticClass: "introduct_item" }, [
            _c("div", { staticClass: "introduct_title" }, [
              _c("span", [_vm._v("驱动")]),
              _vm._v(" 技术创新 "),
            ]),
            _c("div", { staticClass: "content_nr introduct_nrs" }, [
              _vm._v(
                " 能源发展正在由主要依靠资源投入向创新驱动转变。科技、体制和发展模式创新将进一步推动能源清洁化、智能化发展，培育形成新产业和新业态 "
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "introduct_item",
              staticStyle: { margin: "0 50px" },
            },
            [
              _c("div", { staticClass: "introduct_title" }, [
                _c("span", [_vm._v("提升")]),
                _vm._v(" 算力 "),
              ]),
              _c("div", { staticClass: "content_nr introduct_nrs" }, [
                _vm._v(
                  " 在能源行业积累大量的历史数据，如何从数据中挖掘价值，通过数据建模、3D可视化等方式对外提供服务，成为核心亮点 "
                ),
              ]),
            ]
          ),
          _c("div", { staticClass: "introduct_item" }, [
            _c("div", { staticClass: "introduct_title" }, [
              _c("span", [_vm._v("防止")]),
              _vm._v(" 数据泄露 "),
            ]),
            _c("div", { staticClass: "content_nr introduct_nrs" }, [
              _vm._v(
                "及时检测各类漏洞，智能分析并提供安全报告，立体化预防并加固业务安全，防止数据泄漏"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }