<template>
    <div class="nr">
        <div class="bananer_box">
            <div class="bananer_nav">
                <div class="bananer_flex">
                    <div class="bananer_title">
                        <span class="underline">金融</span>
                        行业解决方案
                    </div>
                    <div class="content_nr">
                        面向银行、证劵、基金、保险等行业整体的中间件管理解决方案，在统一平台上管理多种中间件，实现以中间件为纽带解决上下数据流管理问题；在新的架构模式助力金融行业塑造先进的IT运维模式，推动IT运维的全生态发展，符合监管规范与安全要求
                    </div>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="title_level1 titles">方案架构</div>
            <div class="jg_box">
                <div class="jg_left"><img src="../../../assets/solution/finance_nav_img.png" style="width: 100%; height: 100%" /></div>
                <div class="jg_right">
                    <div class="jg_title">可以解决的痛点</div>
                    <div class="content_nr jg_row">
                        <span>●</span>
                        系统规模越来越大，组件越来越多
                    </div>
                    <div class="content_nr jg_row">
                        <span>●</span>
                        业务场景也不断创新，服务依赖关系每天都在变化
                    </div>
                    <div class="content_nr jg_row">
                        <span>●</span>
                        行业竞争，传统的运维模式成本消耗巨大
                    </div>

                    <div class="jg_title" style="margin-top: 50px">我们的优势</div>
                    <div class="content_nr jg_row">
                        <span>●</span>
                        国产化：完全自研
                    </div>
                    <div class="content_nr jg_row">
                        <span>●</span>
                        敏捷性：快速迭代、弹性扩展
                    </div>
                    <div class="content_nr jg_row">
                        <span>●</span>
                        经济性：提升服务效率、降低成本、改善体验
                    </div>
                </div>
            </div>

            <div class="title_level1 titles">方案优势</div>
            <div class="advantage_flex" style="margin-bottom: 40px">
                <div><img src="../../../assets/solution/finance_nav2_img.png" /></div>
                <div class="advantage_right">
                    <div class="advantage_title">安全高效</div>
                    <div class="content_nr">CheerOcean MaaS完全自主研发，统一平台，统一入口，直接通过互联网访问，规范标准流程，提升SLA</div>
                </div>
            </div>

            <div class="advantage_flex" style="margin-bottom: 40px">
                <div class="advantage_right">
                    <div class="advantage_title">数据可视化</div>
                    <div class="content_nr">对客户行为数据和运营数据的可视化分析，形成完整的客户标签和指标体系，为企业决策提供依据</div>
                </div>
                <div><img src="../../../assets/solution/finance_nav3_img.png" /></div>
            </div>

            <div class="advantage_flex">
                <div><img src="../../../assets/solution/finance_nav4_img.png" /></div>
                <div class="advantage_right">
                    <div class="advantage_title">监管合规</div>
                    <div class="content_nr">服务于银行、证券、保险、基金等金融机构。采用独立集群部署，满足一行三会的金融监管要求</div>
                </div>
            </div>

            <div class="title_level1 titles">相关产品</div>
            <div class="product_flex">
                <div class="product_item_left">
                    <div class="product_box">
                        <div class="product_box_left"><img src="../../../assets/solution/finance_icon1.png" width="88px" height="88px" /></div>
                        <div class="product_box_right">
                            <div class="product_title">中间件</div>
                            <div class="content_nr">支持管理多种开源中间件</div>
                        </div>
                    </div>
                </div>
                <div class="product_item_right">
                    <div class="product_box">
                        <div class="product_box_left"><img src="../../../assets/solution/finance_icon2.png" width="88px" height="88px" /></div>
                        <div class="product_box_right">
                            <div class="product_title">金融数据</div>
                            <div class="content_nr">可靠、安全、易用的一站式大数据处理平台</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="product_flex">
                <div class="product_item_left">
                    <div class="product_box">
                        <div class="product_box_left"><img src="../../../assets/solution/finance_icon3.png" width="88px" height="88px" /></div>
                        <div class="product_box_right">
                            <div class="product_title">金融安全</div>
                            <div class="content_nr">高可用方案保障业务系统稳定性</div>
                        </div>
                    </div>
                </div>
                <div class="product_item_right">
                    <div class="product_box">
                        <div class="product_box_left"><img src="../../../assets/solution/finance_icon4.png" width="88px" height="88px" /></div>
                        <div class="product_box_right">
                            <div class="product_title">等保合规</div>
                            <div class="content_nr">提供系统化等保合规建设和测评服务渠道，帮助企业快速满足国家等保要求</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '../../../assets/css/common.css'
export default {
    name: 'Finance',
    data() {
        return {}
    },
    methods: {},
    mounted() {}
}
</script>
<style scoped>
.product_box_right {
    margin-left: 20px;
}
.product_title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 6px;
}
.product_box {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: space-between;
    padding: 20px;
}
.product_item_left {
    margin-right: 20px;
}
.product_item_right {
    margin-left: 20px;
}
.product_item_left,
.product_item_right {
    flex: 1;
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    margin-bottom: 30px;
}
.product_flex {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    align-content: center;
}
/*相关产品*/
.advantage_title {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 20px;
}
.advantage_flex {
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    padding: 30px 5%;
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
/*方案架构*/
.jg_title {
    font-weight: bold;
    font-size: 28px;
    margin: 0 0 30px 0;
}
.jg_left {
    flex: 1;
    width: 100%;
    height: 28rem;
}
.jg_right {
    width: 33%;
    margin-left: 8%;
}
.content_nr span {
    color: #409eff;
    margin-right: 10px;
    font-size: 15px;
}
.jg_row {
    margin: 14px 0;
    font-size: 16px !important;
}
.jg_box {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.box {
    width: 80%;
    margin: 0 auto;
}
/*bananer模块*/
.titles {
    text-align: center;
    color: #191c3d;
    font-size: 32px;
    font-weight: bold;
    padding: 50px;
}
.bananer_title .underline {
    border-bottom: 6px solid #409eff;
    padding-bottom: 6px;
}
.bananer_title {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
}
.bananer_flex {
    width: 500px;
    height: 22rem;
    padding: 40px;
    background: #fff;
}
.bananer_nav {
    padding-top: 5rem;
    padding-left: 12%;
}
.bananer_box {
    width: 100%;
    height: 32rem;
    background-image: url(../../../assets/solution/finance_bananer.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
</style>
