<template>
    <div class="nr">
        <div class="bananer_box">
            <div class="bananer_nav">
                <div class="bananer_flex">
                    <div class="bananer_title">
                        <span class="underline">能源</span>
                        行业解决方案
                    </div>
                    <div class="content_nr">
                        面向石化、电力、新能源等行业整体的中间件管理解决方案，依托自身强大的平台、连接能力，支持一体化服务，快速构建生态化应用
                    </div>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="title_level1 titles">方案介绍</div>
            <div class="introduce_box">
                <div class="introduct_item">
                    <div class="introduct_title">
                        <img src="../../../assets/solution/energy_icon1.png" style="margin-right: 10px" />
                        快速支撑建立应用
                    </div>
                    <div class="content_nr introduct_nr">
                        传统能源行业运维是靠人力维护的模式，在信息化建立及业务转型下，系统实现分钟级的中间件交付能力
                    </div>
                </div>
                <div class="introduct_item" style="margin: 0 50px">
                    <div class="introduct_title">
                        <img src="../../../assets/solution/energy_icon2.png" style="margin-right: 10px" />
                        支撑海量设备接入
                    </div>
                    <div class="content_nr introduct_nr">
                        利用自动扫描系统，全面感知海量异构的用能设备数据，降低数据接入成本并进行高压缩存储，并且平台默认支持数据备份，保证数据安全可靠
                    </div>
                </div>
                <div class="introduct_item">
                    <div class="introduct_title">
                        <img src="../../../assets/solution/energy_icon3.png" style="margin-right: 10px" />
                        实践验证稳定可靠
                    </div>
                    <div class="content_nr introduct_nr">
                        以最高标准践行用户隐私安全相关工作。拥有先进的基础安全架构及完善的数据安全保护体系,能够为⽤户提供从物理到应⽤层⾯的全⽅位安全护航
                    </div>
                </div>
            </div>
            <div class="title_level1 titles">方案优势</div>
            <div class="advantage_flex" style="margin-bottom: 40px">
                <div><img src="../../../assets/solution/energy_img1.png" /></div>
                <div class="advantage_right">
                    <div class="advantage_title">快速接入</div>
                    <div class="content_nr">
                        使用CheerOcean MaaS的标准化快速接入不同类型中间件，使管理员更加便捷，高效的维护和管理，打破传统运维模式
                    </div>
                </div>
            </div>

            <div class="advantage_flex">
                <div class="advantage_right">
                    <div class="advantage_title">全景洞察</div>
                    <div class="content_nr">数据可视化，拥有可配置大屏，进行专业级大屏展示，实时掌握综合能源平台的动态信息，使数据能直观感知</div>
                </div>
                <div><img src="../../../assets/solution/energy_img2.png" /></div>
            </div>

            <div class="title_level1 titles">方案特性</div>
            <div class="introduce_box">
                <div class="introduct_item">
                    <div class="introduct_title">
                        <span>驱动</span>
                        技术创新
                    </div>
                    <div class="content_nr introduct_nrs">
                        能源发展正在由主要依靠资源投入向创新驱动转变。科技、体制和发展模式创新将进一步推动能源清洁化、智能化发展，培育形成新产业和新业态
                    </div>
                </div>
                <div class="introduct_item" style="margin: 0 50px">
                    <div class="introduct_title">
                        <span>提升</span>
                        算力
                    </div>
                    <div class="content_nr introduct_nrs">
                        在能源行业积累大量的历史数据，如何从数据中挖掘价值，通过数据建模、3D可视化等方式对外提供服务，成为核心亮点
                    </div>
                </div>
                <div class="introduct_item">
                    <div class="introduct_title">
                        <span>防止</span>
                        数据泄露
                    </div>
                    <div class="content_nr introduct_nrs">及时检测各类漏洞，智能分析并提供安全报告，立体化预防并加固业务安全，防止数据泄漏</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '../../../assets/css/common.css'
export default {
    name: 'Energy',
    data() {
        return {}
    },
    methods: {},
    mounted() {}
}
</script>
<style scoped>
/*方案介绍*/
.introduct_nr {
    text-align: justify;
}
.introduct_nrs {
    text-align: justify;
    margin-top: 20px;
}
.introduct_title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
}
.introduct_title span {
    border-bottom: 6px solid #409eff;
    padding-bottom: 6px;
}
.introduct_item {
    flex: 1;
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    padding: 30px 30px 60px 30px;
}
.introduce_box {
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    align-content: center;
}
/*相关产品*/
.advantage_title {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 20px;
}
.advantage_flex {
    background: #fff;
    box-shadow: 0px 3px 32px 0px rgba(213, 213, 213, 0.5);
    padding: 30px 5%;
    display: -webkit-flex; /* Safari */
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
/*方案架构*/
.box {
    width: 80%;
    margin: 0 auto;
}
/*bananer模块*/
.titles {
    text-align: center;
    color: #191c3d;
    font-size: 32px;
    font-weight: bold;
    padding: 50px;
}
.bananer_title .underline {
    border-bottom: 6px solid #409eff;
    padding-bottom: 6px;
}
.bananer_title {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: bold;
}
.bananer_flex {
    width: 500px;
    height: 22rem;
    padding: 40px;
    background: #fff;
}
.bananer_nav {
    padding-top: 5rem;
    padding-left: 12%;
}
.bananer_box {
    width: 100%;
    height: 32rem;
    background-image: url(../../../assets/solution/energy_bananer.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
</style>
