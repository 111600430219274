var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nr" }, [
      _c("div", { staticClass: "bananer_box" }, [
        _c("div", { staticClass: "bananer_nav" }, [
          _c("div", { staticClass: "bananer_flex" }, [
            _c("div", { staticClass: "bananer_title" }, [
              _c("span", { staticClass: "underline" }, [_vm._v("金融")]),
              _vm._v(" 行业解决方案 "),
            ]),
            _c("div", { staticClass: "content_nr" }, [
              _vm._v(
                " 面向银行、证劵、基金、保险等行业整体的中间件管理解决方案，在统一平台上管理多种中间件，实现以中间件为纽带解决上下数据流管理问题；在新的架构模式助力金融行业塑造先进的IT运维模式，推动IT运维的全生态发展，符合监管规范与安全要求 "
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "title_level1 titles" }, [_vm._v("方案架构")]),
        _c("div", { staticClass: "jg_box" }, [
          _c("div", { staticClass: "jg_left" }, [
            _c("img", {
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                src: require("../../../assets/solution/finance_nav_img.png"),
              },
            }),
          ]),
          _c("div", { staticClass: "jg_right" }, [
            _c("div", { staticClass: "jg_title" }, [_vm._v("可以解决的痛点")]),
            _c("div", { staticClass: "content_nr jg_row" }, [
              _c("span", [_vm._v("●")]),
              _vm._v(" 系统规模越来越大，组件越来越多 "),
            ]),
            _c("div", { staticClass: "content_nr jg_row" }, [
              _c("span", [_vm._v("●")]),
              _vm._v(" 业务场景也不断创新，服务依赖关系每天都在变化 "),
            ]),
            _c("div", { staticClass: "content_nr jg_row" }, [
              _c("span", [_vm._v("●")]),
              _vm._v(" 行业竞争，传统的运维模式成本消耗巨大 "),
            ]),
            _c(
              "div",
              {
                staticClass: "jg_title",
                staticStyle: { "margin-top": "50px" },
              },
              [_vm._v("我们的优势")]
            ),
            _c("div", { staticClass: "content_nr jg_row" }, [
              _c("span", [_vm._v("●")]),
              _vm._v(" 国产化：完全自研 "),
            ]),
            _c("div", { staticClass: "content_nr jg_row" }, [
              _c("span", [_vm._v("●")]),
              _vm._v(" 敏捷性：快速迭代、弹性扩展 "),
            ]),
            _c("div", { staticClass: "content_nr jg_row" }, [
              _c("span", [_vm._v("●")]),
              _vm._v(" 经济性：提升服务效率、降低成本、改善体验 "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "title_level1 titles" }, [_vm._v("方案优势")]),
        _c(
          "div",
          {
            staticClass: "advantage_flex",
            staticStyle: { "margin-bottom": "40px" },
          },
          [
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("../../../assets/solution/finance_nav2_img.png"),
                },
              }),
            ]),
            _c("div", { staticClass: "advantage_right" }, [
              _c("div", { staticClass: "advantage_title" }, [
                _vm._v("安全高效"),
              ]),
              _c("div", { staticClass: "content_nr" }, [
                _vm._v(
                  "CheerOcean MaaS完全自主研发，统一平台，统一入口，直接通过互联网访问，规范标准流程，提升SLA"
                ),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "advantage_flex",
            staticStyle: { "margin-bottom": "40px" },
          },
          [
            _c("div", { staticClass: "advantage_right" }, [
              _c("div", { staticClass: "advantage_title" }, [
                _vm._v("数据可视化"),
              ]),
              _c("div", { staticClass: "content_nr" }, [
                _vm._v(
                  "对客户行为数据和运营数据的可视化分析，形成完整的客户标签和指标体系，为企业决策提供依据"
                ),
              ]),
            ]),
            _c("div", [
              _c("img", {
                attrs: {
                  src: require("../../../assets/solution/finance_nav3_img.png"),
                },
              }),
            ]),
          ]
        ),
        _c("div", { staticClass: "advantage_flex" }, [
          _c("div", [
            _c("img", {
              attrs: {
                src: require("../../../assets/solution/finance_nav4_img.png"),
              },
            }),
          ]),
          _c("div", { staticClass: "advantage_right" }, [
            _c("div", { staticClass: "advantage_title" }, [_vm._v("监管合规")]),
            _c("div", { staticClass: "content_nr" }, [
              _vm._v(
                "服务于银行、证券、保险、基金等金融机构。采用独立集群部署，满足一行三会的金融监管要求"
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "title_level1 titles" }, [_vm._v("相关产品")]),
        _c("div", { staticClass: "product_flex" }, [
          _c("div", { staticClass: "product_item_left" }, [
            _c("div", { staticClass: "product_box" }, [
              _c("div", { staticClass: "product_box_left" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/solution/finance_icon1.png"),
                    width: "88px",
                    height: "88px",
                  },
                }),
              ]),
              _c("div", { staticClass: "product_box_right" }, [
                _c("div", { staticClass: "product_title" }, [_vm._v("中间件")]),
                _c("div", { staticClass: "content_nr" }, [
                  _vm._v("支持管理多种开源中间件"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "product_item_right" }, [
            _c("div", { staticClass: "product_box" }, [
              _c("div", { staticClass: "product_box_left" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/solution/finance_icon2.png"),
                    width: "88px",
                    height: "88px",
                  },
                }),
              ]),
              _c("div", { staticClass: "product_box_right" }, [
                _c("div", { staticClass: "product_title" }, [
                  _vm._v("金融数据"),
                ]),
                _c("div", { staticClass: "content_nr" }, [
                  _vm._v("可靠、安全、易用的一站式大数据处理平台"),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "product_flex" }, [
          _c("div", { staticClass: "product_item_left" }, [
            _c("div", { staticClass: "product_box" }, [
              _c("div", { staticClass: "product_box_left" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/solution/finance_icon3.png"),
                    width: "88px",
                    height: "88px",
                  },
                }),
              ]),
              _c("div", { staticClass: "product_box_right" }, [
                _c("div", { staticClass: "product_title" }, [
                  _vm._v("金融安全"),
                ]),
                _c("div", { staticClass: "content_nr" }, [
                  _vm._v("高可用方案保障业务系统稳定性"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "product_item_right" }, [
            _c("div", { staticClass: "product_box" }, [
              _c("div", { staticClass: "product_box_left" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/solution/finance_icon4.png"),
                    width: "88px",
                    height: "88px",
                  },
                }),
              ]),
              _c("div", { staticClass: "product_box_right" }, [
                _c("div", { staticClass: "product_title" }, [
                  _vm._v("等保合规"),
                ]),
                _c("div", { staticClass: "content_nr" }, [
                  _vm._v(
                    "提供系统化等保合规建设和测评服务渠道，帮助企业快速满足国家等保要求"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }